import React from "react";
import classNames from "classnames";
import NavLinks from "./NavLinks";
import "./MobileMenu.scss";

const MobileMenu = ({ isVisible }) => {

    const cx = classNames('MobileMenu', {
        '--is-visible': isVisible
    });

    return (
        <div className={cx}><NavLinks/></div>
    )
}

export default MobileMenu;
