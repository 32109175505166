import {Link} from "gatsby";
import React from "react";

const NavLinks = () => {
    return (
        <div className="menu" role="navigation">
            <div className="m-item">
                <Link to="/careweb/" activeClassName="--is-active">Uitbreiden Careweb</Link>
            </div>
            <div className="m-item">
                <Link to="/over-meneer-jansen/" activeClassName="--is-active">Over MeneerJ</Link>
            </div>
            <div className="m-item">
                <Link to="/partners/" activeClassName="--is-active">Partners</Link>
            </div>
            <div className="m-item">
                <Link to="/contact/" activeClassName="--is-active">Contact</Link>
            </div>
        </div>
    );
}

export default NavLinks;
