import React from 'react';
import Hamburger from 'hamburger-react';
import './BurgerIcon.scss';

const BurgerIcon = ({ onClick, isVisble }) => {
    return (
        <div className="MobileNavigation">
            <button type="button" onClick={() => { onClick(!isVisble)}}>
                <span>
                    <Hamburger toggled={isVisble} size={36} color="#000000" />
                </span>
            </button>
        </div>
    );
}

export default BurgerIcon;
