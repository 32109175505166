import React from "react";
import './Footer.scss';
import Logo from "../images/logo.svg";
import {Link} from "gatsby";

const Footer = () => {
    return (
        <footer>
            <div className="top-bar content">
                <div className="company">
                    <Link to="/">
                        <img src={Logo} alt="Logo linked to Home"/>
                    </Link>
                </div>

                <div className="contact-info">
                    <a href="tel:0031614220799"><span className="icon-phone" /> 06 1422 0799</a><br />
                    <a href="mailto:info@meneerjansen-ict.nl"><span className="icon-bubble" /> info@meneerjansen-ict.nl</a>
                </div>

                <div className="social">
                    <a href="https://www.linkedin.com/in/jeroen-jansen-ab52783/" aria-label="LinkedIn profile" target="_blank" rel="noreferrer">
                        <div className="m-item item-linkedin">
                            <span className="icon-linkedin2"/>
                        </div>
                    </a>

                    <a href="https://twitter.com/ictJansen" aria-label="Twitter profile" target="_blank" rel="noreferrer">
                        <div className="m-item item-twitter">
                            <span className="icon-twitter"/>
                        </div>
                    </a>
                </div>
            </div>

            <div className="copyright">
                © Meneer Jansen ICT
            </div>
        </footer>
    )
}

export default Footer;
