import React, {useEffect, useState} from 'react';
import Logo from '../images/logo.svg';
import './Navbar.scss';
import {Link} from "gatsby";
import {useWindowSize} from "../hooks/useWindowSize";
import BurgerIcon from "./BurgerIcon";
import MobileMenu from "./MobileMenu";
import NavLinks from "./NavLinks";


const Navbar = () => {
    const [showBurger, setShowBurger] = useState(false);
    const [showMobileNav, setShowMobileNav] = useState(false);
    const [headerPosition, setHeaderPosition] = useState('0');
    const windowSize = useWindowSize([]);

    useEffect(() => {
        const refWidth = windowSize[0];
        setShowBurger(refWidth > 0 && refWidth <= 768);
        hideNavBar()
    }, [windowSize]);

    const hideNavBar = () => {
        let prevScrollPosition = window.pageYOffset;
        window.onscroll = function() {
            let currentScrollPosition = window.pageYOffset;
            if (prevScrollPosition > currentScrollPosition || window.pageYOffset === 0) {
                setHeaderPosition('0');
            } else {
                if (window.pageYOffset >= 0) {
                    setHeaderPosition('-96px');
                }
            }
            prevScrollPosition = currentScrollPosition;
        }

    }

    return (
        <header style={{top: headerPosition}}>
            <nav>
                <div className="company">
                    <Link to="/">
                        <img src={Logo} alt="Logo linked to Home"/>
                    </Link>
                    <div className="tagline">
                        {/*Zorg software die prettig werkt!*/}
                        Careweb op maat gemaakt..
                    </div>
                </div>

                <div className="links">

                    {showBurger &&
                        <BurgerIcon onClick={setShowMobileNav} isVisble={showMobileNav}/>
                    }

                    {!showBurger &&
                        <NavLinks/>
                    }

                </div>
            </nav>

            <MobileMenu isVisible={showMobileNav && showBurger && headerPosition === '0'}/>

        </header>
    );
}




export default Navbar;
